// import * as Scroll from "react-scroll";
function Intro() {
  // let Link = Scroll.Link;
  return (
    <section className="intro" id="intro">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="intro-wrapper">
              <div className="intro-text-area">
                <h2>WHAT ARE DREADFULZ?</h2>
                <p>7,777 Dreadfulz have inked their name in fealty to the Grand Inquisitor. Bound by avarice, lustful for $DREAD.</p>
                <div className="intro-join-button">
                  <a href="https://opensea.io/collection/dreadfulz" tabIndex={6} className="hover-secondary active-tertiary" target="_blank" rel="noreferrer">JOIN THE DARK SIDE</a>
                </div>
              </div>
              <div className="intro-side-image">
                <img src="/assets/images/intro-side-image.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;