import Accordion from "../../components/accordion";

const panels = [
  {
    label: "What is $DREAD?",
    content: 'Dreadfulz owners may stake to to earn $DREAD through our staking page (<a href="https://stake.dreadfulz.com" target="_blank" rel="noreferrer">stake.dreadfulz.com</a>)<br /><br />$DREAD = Vault Ownership (6.9% of primary mint)<br /><br />$DREAD = DAO Governance (25% of secondary sale royalties)<br /><br />$DREAD = Eco-system Activations (Legendaries, WL Marketplace, MV Claim, Elixir, etc.)<br /><br />Check out our Whitepaper below for more details!<br /><br /><a href="https://medium.com/@dreadfulznft/dreadfulz-whitepaper-the-upside-of-avarice-7a0518b7137f" target="_blank" rel="noreferrer">https://medium.com/@dreadfulznft/dreadfulz-whitepaper-the-upside-of-avarice-7a0518b7137f</a>'
  },
  {
    label: "What is the total supply of Dreadfulz?",
    content:
      '7,777'
  },
  {
    label: "Is there a White paper?",
    content:
      'Our white paper can be read at the following link<br /><a href="https://medium.com/@dreadfulznft/dreadfulz-whitepaper-the-upside-of-avarice-7a0518b7137f" target="_blank" rel="noreferrer">https://medium.com/@dreadfulznft/dreadfulz-whitepaper-the-upside-of-avarice-7a0518b7137f</a>'
  }
];


function FAQ() {
  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="faq-wrapper">
              <div className="faq-image1">
                <img src="/assets/images/faq-1.png" alt="" />
              </div>
              <div className="faq-text-area">
                <h2>FAQ</h2>
              </div>
              <Accordion panels={panels} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;