import * as React from "react";
import * as ReactDOM from 'react-dom';
import HTMLReactParser from "html-react-parser";
import './accordion.scss';
import { ReactComponent as FaqBorderIcon } from "../assets/img/faq-border.svg";

class Panel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            const el = ReactDOM.findDOMNode(this);
            const height = el.querySelector(".panel__inner").scrollHeight;
            this.setState({
                height
            });
        }, 333);
    }

    render() {
        const { label, content, activeTab, index, activateTab } = this.props;
        const { height } = this.state;
        const isActive = activeTab === index;
        const innerStyle = {
            height: isActive ? `${height}px` : '0px'
        };

        return (
            <div className="panel" aria-expanded={isActive}>
                <button className="panel__label" role="tab" onClick={activateTab}>
                    {label}
                </button>
                <div className="panel__inner" style={innerStyle} aria-hidden={!isActive}>
                    {HTMLReactParser('<p className="panel__content">' + content + '</p>')}
                </div>
                <div className="panel__border"><FaqBorderIcon /></div>
            </div>
        );
    }
}

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.activateTab = this.activateTab.bind(this);
    }

    activateTab(index) {
        this.setState((prev) => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }

    render() {
        const { panels } = this.props;
        const { activeTab } = this.state;
        return (
            <div className="accordion" role="tablist">
                {panels.map((panel, index) => (
                    <Panel
                        key={index}
                        activeTab={activeTab}
                        index={index}
                        {...panel}
                        activateTab={this.activateTab.bind(null, index)}
                    />
                ))}
            </div>
        );
    }
}

export default Accordion;