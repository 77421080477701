import './homepage.css';
import Header from "../components/header";
import Intro from "../pages/homepage/intro";
import Roadmap from "../pages/homepage/roadmap";
import Team from "../pages/homepage/team";
import FAQ from "../pages/homepage/faq";
import Section5 from "../pages/homepage/section5";
import StayConnected from "../pages/homepage/stay-connected";
import Footer from "../components/footer";

function Homepage() {
  return (
    <>
      <Header />
      <Intro />
      <Roadmap />
      <Team />
      <FAQ />
      <Section5 />
      <StayConnected />
      <Footer />
    </>
  );
}

export default Homepage;
