import React, { useState, useEffect, useRef } from "react";
import * as Scroll from "react-scroll";
import "./header.css";
import Logo from "../assets/img/logo.svg";
import { ReactComponent as BottomLogo } from "../assets/img/logo.svg";
import { ReactComponent as TwitterIcon } from "../assets/img/twitter.svg";
import { ReactComponent as DiscordIcon } from "../assets/img/discord.svg";
import { ReactComponent as OpenSeaIcon } from "../assets/img/opensea.svg";
// import { ReactComponent as WalletIcon } from "../assets/img/fire.svg";
import { ReactComponent as MobileMenuIcon } from "../assets/img/mobile-menu.svg";
import { ReactComponent as MobileMenuCloseIcon } from "../assets/img/close.svg";

function Header() {
  let Link = Scroll.Link;
  const headerRef = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [gapHeight, setGapHeight] = useState("0");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const styles = {
    gapHeight: {
      display: scrolled ? "block" : "none",
      height: scrolled ? gapHeight : "0",
      width: "100%",
    }
  };
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > headerRef.current.clientHeight) {
      setScrolled(true);
      setGapHeight(headerRef.current.clientHeight + "px");
    }
    else {
      setScrolled(false);
      setGapHeight("0");
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  let navbarClasses = ['header'];
  if (scrolled) {
    navbarClasses.push('scrolled');
  }
  return (
    <>
      <header className={navbarClasses.join(" ")} ref={headerRef}>
        <div className="container">
          <div className="row">
            <div className="col logo-col">
              <a tabIndex={1} href="/" className="logo">
                <img src={Logo} alt="Dreadzfull" />
              </a>
            </div>
            <div className="col nav-col">
              <div className="mobile-menu-button">
                <button onClick={toggleMobileMenu}><MobileMenuIcon /></button>
              </div>
              <div className={isMobileMenuOpen ? 'desktop-menu mobile-menu-full-screen mobile-menu-visible' : 'desktop-menu mobile-menu-full-screen'}>
                <div className="mobile-menu-close-button">
                  <button className="hover-primary-stroke" onClick={toggleMobileMenu}><MobileMenuCloseIcon /></button>
                </div>
                <nav className="nav-links">
                  <Link tabIndex={2} to="roadmap" smooth={true} spy={true} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Roadmap
                  </Link>
                  <Link tabIndex={3} to="team" smooth={true} spy={true} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    Team
                  </Link>
                  <Link tabIndex={4} to="faq" smooth={true} spy={true} activeClass="active" onClick={() => { setIsMobileMenuOpen(false); }}>
                    FAQ
                  </Link>
                  <a tabIndex={5} href="https://stake.dreadfulz.com" target="_blank" rel="noreferrer" onClick={() => { setIsMobileMenuOpen(false); }}>Staking</a>
                </nav>
                <ul className="nav-socials">
                  <li>
                    <a
                      className="hover-primary"
                      href="https://twitter.com/DreadfulzNFT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                      <span className="social-icon-status status-online"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover-primary"
                      href="https://discord.gg/Dreadfulz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DiscordIcon />
                      <span className="social-icon-status status-online"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover-primary"
                      href="https://opensea.io/collection/dreadfulz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <OpenSeaIcon />
                      <span className="social-icon-status status-online"></span>
                    </a>
                  </li>
                </ul>
                <div className="mobile-menu-bottom-logo">
                  <BottomLogo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="header-gap" style={styles.gapHeight}></div>
    </>
  );
}

export default Header;
