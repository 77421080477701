import { ReactComponent as TwitterIcon } from "../../assets/img/twitter.svg";
//import { ReactComponent as BehanceIcon } from "../../assets/img/behance.svg";

function Team() {
  return (
    <section className="team" id="team">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="team-wrapper">
              <div className="team-image1">
                <img src="/assets/images/team-1.png" alt="" />
              </div>
              <div className="team-text-area">
                <h2>TEAM</h2>
                <p>Meet the heads of dread</p>
              </div>
              <div className="team-members">
                <div className="team-member">
                  <div className="team-member-image">
                    <img src="/assets/images/team1.png" alt="" />
                  </div>
                  <h3>SK3LLY</h3>
                  <p>Co-founder</p>
                  <div className="team-member-buttons">
                    <a
                      className="hover-primary"
                      href="https://twitter.com/_sk3lly"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </div>

                </div>
                <div className="team-member">
                  <div className="team-member-image">
                    <img src="/assets/images/team2.png" alt="" />
                  </div>
                  <h3>BERBS</h3>
                  <p>Co-founder</p>
                  <div className="team-member-buttons">

                    <a
                      className="hover-primary"
                      href="https://twitter.com/berbs_d"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </div>

                </div>
                <div className="team-member">
                  <div className="team-member-image">
                    <img src="/assets/images/team3.png" alt="" />
                  </div>
                  <h3>SUPER DREAD</h3>
                  <p>3D Artist/Modeller</p>

                </div>
                <div className="team-member">
                  <div className="team-member-image">
                    <img src="/assets/images/team4.png" alt="" />
                  </div>
                  <h3>kryptoyard</h3>
                  <p>Development Team</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;