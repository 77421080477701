function Section5() {
  return (
    <section className="section5" id="section5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section5-wrapper">
              <div className="section5-bg">
                <img src="/assets/images/section5-bg.png" alt="" />
              </div>
              <div className="section5-content-wrapper">
                <div className="section5-book-image">
                  <img src="/assets/images/book.png" alt="" />
                </div>
                <div className="section5-text-area">
                  <h2>THE UPSIDE OF AVARICE!</h2>
                  <p>
                    <span className="section5-content-caption">UNDEAD WRITINGS!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section5;