import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/twitter.svg";
import { ReactComponent as DiscordIcon } from "../../assets/img/discord.svg";
import { ReactComponent as OpenSeaIcon } from "../../assets/img/opensea.svg";

function StayConnected() {
  return (
    <section className="stay-connected" id="stayconnected">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="stay-connected-wrapper">
              <div className="stay-connected-content-area">
                <h2>STAY CONNECTED</h2>
                <ul className="stay-connected-socials">
                  <li>
                    <a
                      className="hover-primary"
                      href="https://twitter.com/DreadfulzNFT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover-primary"
                      href="https://discord.gg/Dreadfulz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DiscordIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover-primary"
                      href="https://opensea.io/collection/dreadfulz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <OpenSeaIcon />
                    </a>
                  </li>
                </ul>
                <p className="copyright-text">Dreadfulz © {(new Date().getFullYear())}</p>
                <div className="stay-connected-logo">
                  <Logo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StayConnected;