import { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';

function Roadmap() {
  const [showRoadmapPopup, setShowRoadmapPopup] = useState(false);
  const [roadmapPopupText, setRoadmapPopupText] = useState('BLUE VAULT');
  const [roadmapPopupDesc, setRoadmapPopupDesc] = useState("A % of ETH from our primary sale will be used to acquire a range of bluechip NFT's for the Dreadfulz community. Acquired NFT's will be 'fractionalized' via shares and distributed to holders via staking to have investment exposure to the NFT's within the vault.");
  return (
    <>
      <section className="roadmap" id="roadmap">
        <div className="row">
          <div className="col">
            <div className="roadmap-wrapper">
              <div className="roadmap-bg">
                <img src="/assets/images/roadmap-bg.png" alt="" />
              </div>
              <div className="roadmap-content-area">
                <div className="roadmap-title">
                  <h2>Roadmap</h2>
                </div>
                <div className="roadmap-tables">
                  <div className="roadmap-table roadmap-table1">
                    <div className="roadmap-table-bg">
                      <img src="/assets/images/roadmap-table.png" alt="" />
                    </div>
                    <div className="roadmap-table-content">
                      <div>
                        <div>
                          <h3>Vault of Avarice</h3>
                          <button onClick={() => { setShowRoadmapPopup(true); setRoadmapPopupText('Vault of Avarice'); setRoadmapPopupDesc("Rather than providing an initial Liquidity Pool, the total supply of $DREAD will represent 100% ownership of the Vault of Avarice (6.9% of primary mint) & DreadzDAO Governance (25% of royalties)<br /><br />$DREAD = Vault Ownership (6.9% of primary mint)<br /><br />$DREAD = DAO Governance (25% of secondary sale royalties)<br /><br />$DREAD = Roadmap Activations (Legendaries, WL Marketplace, MV Claim, Elixir)<br /><br />The total supply of $DREAD is static and represents 100% of vault ownership and conversely, 100% of DreadzDAO governance."); }}>read more</button></div>
                      </div>
                    </div>
                  </div>
                  <div className="roadmap-table roadmap-table2">
                    <div className="roadmap-table-bg">
                      <img src="/assets/images/roadmap-table.png" alt="" />
                    </div>
                    <div className="roadmap-table-content">
                      <div>
                        <div>
                          <h3>Stake & Earn $DREAD</h3>
                          <button onClick={() => { setShowRoadmapPopup(true); setRoadmapPopupText('Stake & Earn $DREAD'); setRoadmapPopupDesc("Unlike many projects which offer an abundant supply of tokens which diminish in value, $DREAD is inherently deflationary & scarce.<br /><br />$DREAD will fully distribute to stakers within 180 Days. This provides an instant incentive to invest in the Dreadfulz economy, with early adopters being rewarded over laggards or late entrants.<br /><br />Representing 100% of Vault Ownership & DAO Governance, we aim to deliver value to our holders at an accelerated pace. We are able to do this by fusing ownership, governance, & utility into one token; all the while keeping supply scarce.<br /><br />Every time $DREAD is used within the ecosystem it is then burnt from the supply. Will you spend or stack your $DREAD?<br /><br />Read Whitepaper here: <a href='https://medium.com/@dreadfulznft' target='_blank' rel='noreferrer'>https://medium.com/@dreadfulznft</a>"); }}>read more</button></div></div>
                    </div>
                  </div>
                  <div className="roadmap-table roadmap-table3">
                    <div className="roadmap-table-bg">
                      <img src="/assets/images/roadmap-table.png" alt="" />
                    </div>
                    <div className="roadmap-table-content">
                      <div>
                        <div>
                          <h3>Form the DreadzDAO</h3>
                          <button onClick={() => { setShowRoadmapPopup(true); setRoadmapPopupText('Form the DreadzDAO'); setRoadmapPopupDesc('The DAO of the Dreadfulz will be focused on maximising value to holders of $DREAD. All members of the DAO can expect capital appreciation of the vault and initiatives that aim to expand the wealth contained within the DAO.<br /><br />DreadzDAO operates as a wholly independent entity beyond the Dreadfulz ecosystem. The Core Dreadfulz Team will act as initial council members to ensure the timely establishment of the DAO and use it as a proving ground to explore various revenue and capital appreciation schemes, aimed at providing maximum value back to $DREAD holders.<br /><br />Here are some things we plan to implement to bring value back to our holders in the first phase:<br /><br />- Top tier Alpha Channel (Callers, analysts, tools)<br />- Exploring investments with small Web3 startups<br />- Community led project with % of ownership to the DAO<br />- Vault expansion'); }}>read more</button></div></div>
                    </div>
                  </div>
                  <div className="roadmap-table roadmap-table4">
                    <div className="roadmap-table-bg">
                      <img src="/assets/images/roadmap-table.png" alt="" />
                    </div>
                    <div className="roadmap-table-content">
                      <div>
                        <div>
                          <h3>Metaverse-Ready 3D Model Claim</h3>
                          <button onClick={() => { setShowRoadmapPopup(true); setRoadmapPopupText('Metaverse-Ready 3D Model Claim'); setRoadmapPopupDesc('Spread your curse through the metaverse<br /><br />Verify your Dreadfulz and claim your full-body 3D model for plug and play into compatible Metaverses. Unleash the evil upon unsuspecting avatars and watch them squirm..'); }}>read more</button></div></div>
                    </div>
                  </div>
                  <div className="roadmap-table roadmap-table5">
                    <div className="roadmap-table-bg">
                      <img src="/assets/images/roadmap-table.png" alt="" />
                    </div>
                    <div className="roadmap-table-content">
                      <div>
                        <div>
                          <h3>The Elixir</h3>
                          <button onClick={() => { setShowRoadmapPopup(true); setRoadmapPopupText('The Elixir'); setRoadmapPopupDesc('What happens when I drink this?<br /><br />After many months of chemical concoction, the Elixir grants your Dreadfulz the balm and blessing of redemption - a chance to cure your Dreadfulz of their wicked ways, once and for all! But be careful what you wish for…'); }}>read more</button></div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showRoadmapPopup ? (<section className="roadmap-popup">
        <div className="roadmap-popup-wrapper">
          <div className="roadmap-popup-content">
            <button className='close hover-secondary-stroke' onClick={() => { setShowRoadmapPopup(false); }}><CloseIcon /></button>
            <h2>
              {roadmapPopupText}
            </h2>
            <p>{HTMLReactParser(roadmapPopupDesc)}</p>
          </div>
        </div>
      </section>) : ''}
    </>
  );
}

export default Roadmap;